export default {
    confirmationCode: {
        name: "confirmationCode",
        id: "confirmationCode",
        type: "number",
        required: true,
        value: "",
        label: "Kod z email",
        valid: false,
        minLength: 6,
        maxLength: 6,
    },
};
