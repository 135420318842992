import React from "react";
import { Router } from "@reach/router";

// PAGES
import Login from "../auth/Login";
import GoogleAuth from "../auth/GoogleAuth";
import Register from "../auth/Register";
import ForgotPassword from "../auth/ForgotPassword";
import PrivateRoute from "../auth/PrivateRoute";

// PRIVATE PAGES
import Account from "../_pages/Account";
import MyAchievements from "../_pages/MyAchievements";
import MyRoutes from "../_pages/MyRoutes";
import AccountEdit from "../_pages/AccountEdit";

const App = () => (
    <Router>
        <PrivateRoute component={Account} path="/app/moje-konto" />
        <PrivateRoute component={MyAchievements} path="/app/moje-osiagniecia" />
        <PrivateRoute component={MyRoutes} path="/app/moje-trasy" />
        <PrivateRoute component={AccountEdit} path="/app/edycja-konta" />
        <Login path="/app/logowanie" />
        <GoogleAuth path="/app/google/connect" />
        <Register path="/app/rejestracja" />
        <ForgotPassword path="/app/przypomnienie-hasla" />
    </Router>
);

export default App;
