import React from "react";

// STYLE
import "./authTabNav.scss";

import Link from "../../atoms/Link/Link";

const AuthTabNav = ({ redirectUrl }) => {
    return (
        <div className="tab-nav">
            <Link to="/app/logowanie" state={{ redirectUrl: redirectUrl }}>
                Zaloguj się
            </Link>
            <Link to="/app/rejestracja" state={{ redirectUrl: redirectUrl }}>
                Zarejestruj się
            </Link>
        </div>
    );
};

export default AuthTabNav;
