import React from "react";
import Link from "../../atoms/Link/Link";

import Icon from "../../atoms/Icon/Icon";

import "./myAccountMenuItem.scss";

const MyAccountMenuItem = ({ name, icon, url, onClick }) => {
    return url ? (
        <Link className="my-account--menu-item" to={url}>
            <div className="my-account--menu-item-icon">
                <Icon className={`icon--alt icon-${icon}`} />
            </div>
            {name}
            <div className="my-account--menu-item-chevron">
                <Icon className="icon-chevron-left-min" />
                <span className="sr-only">Cofnij do poprzedniego widoku</span>
            </div>
        </Link>
    ) : (
        <div
            className="my-account--menu-item my-account--menu-item__clickable"
            onClick={() => onClick()}
        >
            <div className="my-account--menu-item-icon">
                <Icon className={`icon--alt icon-${icon}`} />
            </div>
            {name}
            <div className="my-account--menu-item-chevron dsdsd">
                <Icon className="icon-chevron-left-min" />
                <span className="sr-only">Cofnij do poprzedniego widoku</span>
            </div>
        </div>
    );
};

export default MyAccountMenuItem;
