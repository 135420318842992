import React from "react";
import { useLocation } from "@reach/router";
import { useAuth } from "../hooks/redux";
import { navigate } from "gatsby";

const PrivateRoute = ({ component: Component, ...props }) => {
    const location = useLocation();
    const [auth] = useAuth();

    if (!auth.isLoggedIn && location.pathname !== `/app/logowanie`) {
        navigate("/app/logowanie");

        return null;
    } else {
        return <Component {...props} />;
    }
};

export default PrivateRoute;
