import React from "react";
import { Alert } from "reactstrap";

// STYLE
import "./resetPasswordModal.scss";

// COMPONENTS
import Button from "../../atoms/Button/Button";
import Heading from "../../atoms/Heading/Heading";
import Modal from "../../molecules/Modal/Modal";
import Paragraph from "../../atoms/Paragraph/Paragraph";
import FormElement from "../../atoms/FormElement/FormElement";

const ResetPasswordModal = ({
    modal,
    setModal,
    formData,
    handleOnChange,
    handleOnBlur,
    onSubmit,
    isLoading,
    resetFormErrorMsg,
    isResetFormValid,
}) => (
    <Modal
        className="reset-password-modal"
        modal={modal}
        setModal={() => setModal(false)}
        BottomActions={() => (
            <Button
                className={`btn--primary btn--md btn-block ${
                    isLoading ? "btn--loading" : ""
                }`}
                onClick={onSubmit}
                disabled={isLoading || !isResetFormValid(formData)}
            >
                Dalej
            </Button>
        )}
    >
        <Heading as="h1">Weryfikacja kodu</Heading>
        <Paragraph className="reset-password-modal--desc">
            Na podany adres email została wysłana wiadomość z kodem
            weryfikacyjnym. Proszę wprowadzić go poniżej razem z nowym hasłem.
        </Paragraph>

        {resetFormErrorMsg && <Alert color="danger">{resetFormErrorMsg}</Alert>}

        <FormElement
            {...formData.confirmationCode}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
        />
        <FormElement
            {...formData.password}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
        />
        <FormElement
            {...formData.passwordConfirmation}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
        />
    </Modal>
);

export default ResetPasswordModal;
