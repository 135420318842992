import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import axios from "axios";
import { useDispatch } from "react-redux";
import { navigate } from "gatsby";

import Layout from "../layout";
import SEO from "../components/seo";

import { Container, Row, Col, Alert } from "reactstrap";

// ACTIONS
import { logIn } from "../state/actions";

const GoogleAuth = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [error, setError] = useState(false);

    useEffect(() => {
        const authorize = async () => {
            try {
                const response = await axios.get(
                    `${process.env.STRAPI_API_URL}/auth/google/callback?${location.search}`
                );

                setError(false);
                dispatch(logIn(response.data));

                // NAVIGATE
                navigate("/app/moje-konto");
            } catch (error) {
                console.log(error);
                setError(true);
            }
        };

        authorize();
    }, []);

    return (
        <Layout>
            <SEO title="Logowanie via Google" />
            <Container>
                {error && (
                    <Row className="d-flex justify-content-center mt-5">
                        <Col lg={10}>
                            <Alert color="danger">
                                Logowanie nie powiodło się.
                            </Alert>
                        </Col>
                    </Row>
                )}
            </Container>
        </Layout>
    );
};

export default GoogleAuth;
