import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { navigate, Link as GatsbyLink } from "gatsby";
import { Container, Row, Col } from "reactstrap";

// RECAPTCHA
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

// ACTIONS
import { hideHeader, hideBottomNav } from "../state/actions";

// COMPONENTS
import Layout from "../layout";
import SEO from "../components/seo";
import Heading from "../components/atoms/Heading/Heading";
import Paragraph from "../components/atoms/Paragraph/Paragraph";
import ForgotPasswordForm from "../components/organisms/ForgotPasswordForm/ForgotPasswordForm";
import ResetPasswordModal from "../components/organisms/ResetPasswordModal/ResetPasswordModal";
import forgotPasswordFormModel from "../formModels/forgotPasswordFormModel";
import resetPasswordFormModel from "../formModels/resetPasswordFormModel";
import Icon from "../components/atoms/Icon/Icon";
import Button from "../components/atoms/Button/Button";

// UTILS
import { changeHandler, blurHandler, validateForm } from "../utils/forms";

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const [forgotForm, setForgotForm] = useState(forgotPasswordFormModel);
    const [resetForm, setResetForm] = useState(resetPasswordFormModel);
    const [isLoading, setIsLoading] = useState(false);
    const [isReset, setIsReset] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [forgotFormErrorMsg, setForgotFormErrorMsg] = useState("");
    const [resetFormErrorMsg, setResetFormErrorMsg] = useState("");

    // INITIALIZE RECAPTCHA
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        dispatch(hideHeader());
        dispatch(hideBottomNav());
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        // Check if the captcha was skipped or not
        if (!executeRecaptcha) {
            setIsLoading(false);
            return;
        }

        // GET RECAPTCHA TOKEN
        const token = await executeRecaptcha();

        const url = isReset
            ? "/auth/reset-password"
            : "/auth/forgot-password-email";
        let dataToSend = {};

        dataToSend.token = token;

        if (isReset) {
            dataToSend.code = resetForm.confirmationCode.value;
            dataToSend.password = resetForm.password.value;
            dataToSend.passwordConfirmation =
                resetForm.passwordConfirmation.value;
        } else {
            dataToSend.email = forgotForm.email.value;
        }

        axios
            .post(`${process.env.STRAPI_API_URL}${url}`, dataToSend)
            .then((res) => {
                setIsLoading(false);
                if (isReset) {
                    setIsReset(false);
                    setResetFormErrorMsg("");
                    setIsSuccess(true);
                } else {
                    setForgotForm(forgotPasswordFormModel);
                    setForgotFormErrorMsg("");
                    setIsReset(true);
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                if (isReset) {
                    setResetFormErrorMsg(
                        "Nie udało się zmienić hasła. Upewnij się, że wpisany kod jest poprawny i spróbuj ponownie."
                    );
                } else {
                    setForgotFormErrorMsg(
                        "Nie znaleziono użytkownika o takim adresie e-mail"
                    );
                }
            });
    };

    return (
        <Layout>
            <SEO title="Zapomniałeś hasła?" />

            <Container>
                <Row>
                    <Col>
                        <Button
                            className="btn--circle btn--back"
                            onClick={() => navigate("/app/logowanie")}
                            aria-label="Cofnij do poprzedniego widoku"
                        >
                            <Icon className="icon-chevron-left-min" />
                        </Button>
                    </Col>
                </Row>
            </Container>

            <Container className="auth-wrapper">
                <Row>
                    <Col lg={{ size: 10, offset: 1 }}>
                        {!isSuccess && (
                            <>
                                <Heading as="h1">
                                    Wyślij wiadomość, aby zresetować hasło
                                </Heading>
                                <ForgotPasswordForm
                                    formData={forgotForm}
                                    onSubmit={onSubmit}
                                    isLoading={isLoading}
                                    forgotFormErrorMsg={forgotFormErrorMsg}
                                    isForgotFormValid={validateForm}
                                    handleOnChange={changeHandler.bind(
                                        null,
                                        setForgotForm
                                    )}
                                    handleOnBlur={blurHandler.bind(
                                        null,
                                        setForgotForm
                                    )}
                                />

                                <ResetPasswordModal
                                    modal={isReset}
                                    setModal={setIsReset}
                                    formData={resetForm}
                                    onSubmit={onSubmit}
                                    isLoading={isLoading}
                                    resetFormErrorMsg={resetFormErrorMsg}
                                    isResetFormValid={validateForm}
                                    handleOnChange={changeHandler.bind(
                                        null,
                                        setResetForm
                                    )}
                                    handleOnBlur={blurHandler.bind(
                                        null,
                                        setResetForm
                                    )}
                                />
                            </>
                        )}

                        {isSuccess && (
                            <>
                                <Heading as="h1">
                                    Hasło zostało zmienione
                                </Heading>
                                <Paragraph>
                                    Twoje hasło zostało zmienione, przejdź do
                                    formularza logowania, aby za jego pomocą
                                    zalogować się do serwisu.
                                </Paragraph>
                                <div className="text-center mt-5">
                                    <GatsbyLink
                                        to="/app/logowanie"
                                        className="btn btn--primary"
                                    >
                                        Zaloguj się
                                    </GatsbyLink>
                                </div>
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default ForgotPassword;
