import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideSideDrawer, showBottomNav, hideHeader } from "../state/actions";
import { useAuth } from "../hooks/redux";
import useRoutes from "../hooks/redux/useRoutes";

import { Container, Row, Col } from "reactstrap";
import Layout from "../layout";
import SEO from "../components/seo";
import MyAccountHeader from "../components/molecules/MyAccountHeader/MyAccountHeader";
import MyAccountRouteBox from "../components/molecules/MyAccountRouteBox/MyAccountRouteBox";
import Link from "../components/atoms/Link/Link";

const MyRoutes = () => {
    const [{ routes }] = useRoutes();
    const dispatch = useDispatch();
    const [auth] = useAuth();

    const startedRoutes = routes?.filter(
        (route) => auth.data.started_routes.indexOf(route.id) !== -1
    );
    const finishedRoutes = routes?.filter(
        (route) => auth.data.finished_routes.indexOf(route.id) !== -1
    );
    const favoriteRoutes = routes?.filter(
        (route) => auth.data.favorites.indexOf(route.id) !== -1
    );
    const showMessage =
        !startedRoutes.length &&
        !finishedRoutes.length &&
        !favoriteRoutes.length;

    useEffect(() => {
        dispatch(hideHeader());
        dispatch(showBottomNav());
        dispatch(hideSideDrawer());
    }, []);

    return (
        <Layout>
            <SEO title="Moje konto" />

            <MyAccountHeader />

            <Container className="my-account my-account-routes">
                {showMessage ? (
                    <Row>
                        <Col lg={{ size: 10, offset: 1 }}>
                            <div className="my-account-routes--message">
                                <div>Jeszcze nie posiadasz żadnych tras</div>
                                <Link
                                    className="btn btn--primary btn--md"
                                    to="/trasy"
                                    title="Przejdź do listy tras"
                                >
                                    Rozpocznij przygodę
                                </Link>
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col lg={{ size: 10, offset: 1 }}>
                            <div className="my-account--heading">
                                Rozpoczęte trasy
                            </div>
                            <div>
                                {!startedRoutes.length ? (
                                    <div className="text-center text-muted my-5">
                                        Brak rozpoczętych tras
                                    </div>
                                ) : (
                                    startedRoutes.map((started, index) => (
                                        <MyAccountRouteBox
                                            {...started}
                                            key={index}
                                            progress={60}
                                        />
                                    ))
                                )}
                            </div>

                            <div className="my-account--heading">
                                Zakończone trasy
                            </div>
                            <div>
                                {!finishedRoutes.length ? (
                                    <div className="text-center text-muted my-5">
                                        Brak zakończonych tras
                                    </div>
                                ) : (
                                    finishedRoutes.map((finished, index) => (
                                        <MyAccountRouteBox
                                            {...finished}
                                            key={index}
                                            progress={100}
                                        />
                                    ))
                                )}
                            </div>

                            <div className="my-account--heading">
                                Ulubione trasy
                            </div>
                            <div>
                                {!favoriteRoutes.length ? (
                                    <div className="text-center text-muted my-5">
                                        Brak ulubionych tras
                                    </div>
                                ) : (
                                    favoriteRoutes.map((fovorite, index) => (
                                        <MyAccountRouteBox
                                            {...fovorite}
                                            key={index}
                                        />
                                    ))
                                )}
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Layout>
    );
};

export default MyRoutes;
