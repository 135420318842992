export default {
    email: {
        name: "email",
        id: "email",
        type: "email",
        required: true,
        value: "",
        label: "E-mail",
        valid: false,
    },
    password: {
        name: "password",
        id: "password",
        type: "password",
        required: true,
        value: "",
        label: "Hasło",
        valid: false,
    },
};
