export default {
    name: {
        name: "name",
        id: "name",
        type: "text",
        required: true,
        value: "",
        label: "Nazwa użytkownika",
        valid: false,
        minLength: 3,
    },
    email: {
        name: "email",
        id: "email",
        type: "email",
        required: true,
        value: "",
        label: "E-mail",
        valid: false,
    },
    password: {
        name: "password",
        id: "password",
        type: "password",
        required: false,
        value: "",
        label: "Hasło",
        valid: false,
    },
    passwordConfirmation: {
        name: "passwordConfirmation",
        id: "passwordConfirmation",
        type: "password",
        required: false,
        value: "",
        label: "Powtórz hasło",
        valid: false,
    },
};
