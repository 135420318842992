import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { navigate } from "gatsby";
import { Container, Row, Col } from "reactstrap";

// RECAPTCHA
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

// ACTIONS
import {
    logIn,
    hideHeader,
    hideBottomNav,
    hideSideDrawer,
} from "../state/actions";

// COMPONENTS
import Layout from "../layout";
import SEO from "../components/seo";
import Heading from "../components/atoms/Heading/Heading";
import AuthTabNav from "../components/molecules/AuthTabNav/AuthTabNav";
import RegisterForm from "../components/organisms/RegisterForm/RegisterForm";
import RegisterConfirmModal from "../components/organisms/RegisterConfirmModal/RegisterConfirmModal";
import registrationFormModel from "../formModels/registrationFormModel";
import registrationConfirmationFormModel from "../formModels/registrationConfirmationFormModel";
import Icon from "../components/atoms/Icon/Icon";
import Button from "../components/atoms/Button/Button";

// UTILS
import { changeHandler, blurHandler, validateForm } from "../utils/forms";

const Register = ({ location }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [registerForm, setRegisterForm] = useState(registrationFormModel);
    const [confirmForm, setConfirmForm] = useState(
        registrationConfirmationFormModel
    );
    const [formErrorMsg, setFormErrorMsg] = useState("");
    const [isConfirm, setIsConfirm] = useState(false);
    const [confirmationErrorMsg, setConfirmationErrorMsg] = useState("");
    const [userId, setUserId] = useState("");

    // INITIALIZE RECAPTCHA
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        dispatch(hideHeader());
        dispatch(hideBottomNav());
        dispatch(hideSideDrawer());
    }, []);

    const sendForm = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        // Check if the captcha was skipped or not
        if (!executeRecaptcha) {
            setIsLoading(false);
            return;
        }

        // GET RECAPTCHA TOKEN
        const token = await executeRecaptcha();

        axios
            .post(`${process.env.STRAPI_API_URL}/auth/local/register`, {
                name: registerForm.name.value,
                email: registerForm.email.value,
                password: registerForm.password.value,
                token,
            })
            .then((res) => {
                setIsLoading(false);
                setRegisterForm(registrationFormModel);
                setFormErrorMsg("");
                setIsConfirm(true);
                setUserId(res.data.user.id);
                localStorage.setItem("isPolicyAccepted", true);
            })
            .catch((err, data) => {
                console.log(err);
                const isPhoneTaken =
                    JSON.stringify(err.response.data).indexOf(
                        "error.phone.taken"
                    ) !== -1;

                setFormErrorMsg(
                    isPhoneTaken
                        ? "Istnieje już konto z podanym adresem email"
                        : "Rejestracja nie powiodła się. Jeśli błąd będzie się powtarzał, skontaktuj się z admninistratorem"
                );
                setIsLoading(false);
            });
    };

    const sendConfirmationCode = (e) => {
        e.preventDefault();

        setIsLoading(true);

        axios
            .get(
                `${process.env.STRAPI_API_URL}/auth/email-confirmation?confirmation=${confirmForm.confirmationCode.value}&userid=${userId}`
            )
            .then((res) => {
                setIsLoading(false);
                setRegisterForm(registrationFormModel);
                setConfirmationErrorMsg("");
                dispatch(logIn(res.data));
                navigate("/app/moje-konto", { state: { firstLogin: true } });
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                setConfirmationErrorMsg(
                    "Nie udało się potwierdzić rejestracji. Upewnij się, że wpisany kod jest poprawny i spróbuj ponownie."
                );
            });
    };

    return (
        <Layout>
            <SEO title="Rejestracja" />

            <Container>
                <Row>
                    <Col>
                        <Button
                            className="btn--circle btn--back"
                            onClick={() => navigate(-1)}
                            aria-label="Cofnij do poprzedniego widoku"
                        >
                            <Icon className="icon-chevron-left-min" />
                        </Button>
                    </Col>
                </Row>
            </Container>

            <Container className="auth-wrapper">
                <Row>
                    <Col lg={{ size: 10, offset: 1 }}>
                        <AuthTabNav
                            redirectUrl={location?.state?.redirectUrl}
                        />

                        <Heading as="h1">Załóż konto</Heading>

                        <RegisterForm
                            formData={registerForm}
                            onSubmit={sendForm}
                            isLoading={isLoading}
                            isFormValid={validateForm}
                            formErrorMsg={formErrorMsg}
                            handleOnChange={changeHandler.bind(
                                null,
                                setRegisterForm
                            )}
                            handleOnBlur={blurHandler.bind(
                                null,
                                setRegisterForm
                            )}
                        />

                        <RegisterConfirmModal
                            modal={isConfirm}
                            setModal={setIsConfirm}
                            onSubmit={sendConfirmationCode}
                            formData={confirmForm}
                            confirmationErrorMsg={confirmationErrorMsg}
                            isLoading={isLoading}
                            isFormValid={validateForm}
                            userId={userId}
                            handleOnChange={changeHandler.bind(
                                null,
                                setConfirmForm
                            )}
                            handleOnBlur={blurHandler.bind(
                                null,
                                setConfirmForm
                            )}
                        />
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default Register;
