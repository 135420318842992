import React from "react";

// STYLE
import "./forgotPasswordForm.scss";

// COMPONENTS
import { Form, Alert } from "reactstrap";
import FormElement from "../../atoms/FormElement/FormElement";
import Button from "../../atoms/Button/Button";
import Link from "../../atoms/Link/Link";

const ForgotPasswordForm = ({
    formData,
    handleOnChange,
    handleOnBlur,
    onSubmit,
    isLoading,
    forgotFormErrorMsg,
    isForgotFormValid,
}) => (
    <>
        {forgotFormErrorMsg && (
            <Alert color="danger">{forgotFormErrorMsg}</Alert>
        )}

        <Form className="forgot-password-form" onSubmit={onSubmit}>
            <FormElement
                {...formData.email}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            />
            <Button
                className={`btn--primary btn--md ${
                    isLoading ? "btn--loading" : ""
                }`}
                disabled={isLoading || !isForgotFormValid(formData)}
                type="submit"
            >
                Wyślij wiadomość
            </Button>

            <div className="forgot-password-form--back">
                <Link to="/app/logowanie">Powrót do logowania</Link>
            </div>
        </Form>
    </>
);

export default ForgotPasswordForm;
