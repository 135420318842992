import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { Container, Row, Col, Alert } from "reactstrap";
import { useDispatch } from "react-redux";

import Layout from "../layout";
import SEO from "../components/seo";
import {
    hideSideDrawer,
    showBottomNav,
    hideHeader,
    logOut,
} from "../state/actions";
import MyAccountHeader from "../components/molecules/MyAccountHeader/MyAccountHeader";
import MyAccountMenuItem from "../components/molecules/MyAccountMenuItem/MyAccountMenuItem";

const Account = ({ location }) => {
    const dispatch = useDispatch();
    const [msg, setMsg] = useState({});

    useEffect(() => {
        dispatch(hideHeader());
        dispatch(showBottomNav());
        dispatch(hideSideDrawer());

        if (location?.state?.firstLogin) {
            setMsg({
                style: "success",
                value: "Twoje konto zostało zarejestrowane.",
            });
        }
    }, []);

    const logOutHandler = () => {
        dispatch(logOut());
        navigate("/app/logowanie");
    };

    return (
        <Layout>
            <SEO title="Moje konto" />

            <MyAccountHeader backUrl="/trasy" />

            <Container className="my-account mt-0">
                <Row>
                    <Col lg={{ size: 10, offset: 1 }}>
                        {msg.value && (
                            <Alert color={msg.style} className="mt-3 mb-0">
                                {msg.value}
                            </Alert>
                        )}

                        <MyAccountMenuItem
                            name="Moje trasy"
                            icon="track-min"
                            url="/app/moje-trasy"
                        />
                        <MyAccountMenuItem
                            name="Moje osiągniecia"
                            icon="award-min"
                            url="/app/moje-osiagniecia"
                        />
                        {/* <MyAccountMenuItem
                            name="Powiadomienia"
                            icon="bell-min"
                            url="/powiadomienia"
                        /> */}
                        <MyAccountMenuItem
                            name="Edycja konta"
                            icon="user-min"
                            url="/app/edycja-konta"
                        />
                        <MyAccountMenuItem
                            name="Wyloguj"
                            icon="arrow-back"
                            onClick={logOutHandler}
                        />
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default Account;
