import React from "react";

// STYLE
import "./loginForm.scss";

// COMPONENTS
import { Form, Alert } from "reactstrap";
import FormElement from "../../atoms/FormElement/FormElement";
import Button from "../../atoms/Button/Button";
import PlainText from "../../atoms/PlainText/PlainText";
import Link from "../../atoms/Link/Link";

const LoginForm = ({
    formData,
    handleOnChange,
    handleOnBlur,
    onSubmit,
    isLoading,
    isFormValid,
    formErrorMsg,
}) => (
    <>
        <Form className="login-form" onSubmit={onSubmit}>
            {formErrorMsg && <Alert color="danger">{formErrorMsg}</Alert>}

            <FormElement
                {...formData.email}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            />
            <FormElement
                {...formData.password}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            />

            <div className="login-form--forgot-link-wrapper">
                <Link
                    to="/app/przypomnienie-hasla"
                    className="login-form--forgot-link"
                >
                    Nie pamiętasz hasła?
                </Link>
            </div>

            <Button
                className={`btn--primary btn--md ${
                    isLoading ? "btn--loading" : ""
                }`}
                disabled={isLoading || !isFormValid(formData)}
                type="submit"
            >
                Zaloguj się
            </Button>
        </Form>

        <div className="login-form--additional">
            <PlainText>Nie posiadasz konta?</PlainText>
            <Link to="/app/rejestracja">Zarejestruj się</Link>
        </div>
    </>
);

export default LoginForm;
