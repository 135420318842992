export default {
    email: {
        name: "email",
        id: "email",
        type: "email",
        required: true,
        value: "",
        label: "E-mail",
        valid: false,
    },
};
